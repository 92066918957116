
  button {font-family:"SpoqaHanSans";}
	input, textarea {outline:none}
	textarea {overflow:auto !important}
	input:-ms-input-placeholder, textarea:-ms-input-placeholder {
		color: #a6a6a6 !important;
	}
	input::-ms-clear, input::-ms-reveal {
		display: none;
	}
	.zuix_svg svg {width:100% !important; height:100% !important; display: flex;}

    body::-webkit-scrollbar, div::-webkit-scrollbar { width:6px; }
    body::-webkit-scrollbar-thumb,
    div::-webkit-scrollbar-thumb { background-color: #cccccc; border-radius: 3px !important}
    body::-webkit-scrollbar-track,
    div::-webkit-scrollbar-track {background-color: #fafafa;}


    .indicatorAView {
        flex:0 0 auto;
        align-self: center;
        background-color: transparent;
    }
    .indicatorA {
        position:relative;
        width: 36px;
        height: 36px;
    }
    .indicatorA .animationDuration {
        width:100%; height:100%;
        -ms-flex-align:center;-webkit-align-items:center;-webkit-box-align:center;align-items:center;
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-name: anim;
        animation-name: anim;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    .indicatorA .item-circle-background {
        stroke: rgb(117, 117, 117);
        opacity: 0.2;
    }
    .indicatorA .item-circle-mover {
        stroke: rgb(117, 117, 117);
        stroke-dasharray: 80;
        stroke-dashoffset: 60;
    }

    @-webkit-keyframes anim {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
        }
    }

    @keyframes anim {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
        }
    }


    .invisible-scrollbar {
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
    }
    .invisible-scrollbar::-webkit-scrollbar { width: 0 !important; display: none; }

    .zuix_lnb_container {
      width: 240px;
      height: 100%;
      overflow: auto;
    }

    .zuix_layouta_container {
        width: 100%; height: 100%;
        display: flex; justify-content: center; align-items: center;
    }
    .zuix_layouta_content {
        min-height: 488px; max-height: 543px; max-width: 380px; min-width: 380px;
        display: flex; flex-basis: auto; flex-grow: 1; overflow: hidden; background: #fff;
		flex-direction: column;
    }
    .zuix_layoutb_container, .zuix_layoutc_container, .zuix_layoutd_container {
        width: 100%; min-height: 100%;
    }
	.zuix_layoutb_container, .zuix_layoutc_container {
		display:flex; flex:1 1 auto;
		flex-direction: column;
	}
	.zuix_layoutb_container {
		justify-content:space-between
	}
    .zuix_layoutb_header, .zuix_layoutc_header, .zuix_layoutd_header {
        width: 100%; height: 60px;
        position: fixed; left:0; right:0; top:0; z-index:1;
    }
    .zuix_layoutb_content {
        max-width: 1000px;
		width:100%; flex:1 1 auto;
		padding-top:75px;
        display: flex; flex-direction: row;
        margin: 0 auto; justify-content: center;
        background: #fff;
    }
    .zuix_layoutc_content, .zuix_layoutd_content {
        max-width: 600px; flex:1 1 auto; width:100%;
        display: flex; flex-direction: row;
        margin: 0 auto; padding-top: 75px; justify-content: center;
        background: #fff;
    }
    .zuix_layoutb_footer, .zuix_layoutc_footer {
        width: 100%; min-height: 50px;
        display: flex; display: -webkit-flex;
		flex:1 0 auto;
    }
    .zuix_layoutb_bottom_fixed, .zuix_layoutc_bottom_fixed {
        width: 100%; min-height: 50px;
        position: fixed; left: 0; bottom: 0; right: 0;
        display: none;
    }
    .zuix_layoutd_bottom_fixed {
        width: 100%; min-height: 50px;
        position: fixed; left: 0; bottom: 0; right: 0;
    }

  .partner_profile_b_conatiner div {
    background-position:top;
  }

	.zuix_layoutb_bottom_fixed:empty, .zuix_layoutc_bottom_fixed:empty, .zuix_layoutd_bottom_fixed:empty {
		display:none !important;
	}
  .zuix_lnb_menu_item :hover {
    background: rgba(250, 149, 11, 0.08);
  }
  .zuix_lnb_menu_item > div > div {
    pointer-events: none;
  }
	.zuix_layoutf_container {position:relative; padding:65px 0 0 240px; background:#fff;}
	.zuix_layoutf_container .header {display:flex; flex: 0 1 auto; position:fixed; left:0; right:0; top:0; width:100%; height:64px; z-index:1; border-bottom:1px solid #eee; background:#fff;}
	.zuix_layoutf_container .lnb {display:flex; flex: 0 1 auto; position:fixed; left:0; top:64px; width:240px; bottom:0; border-right:1px solid #eee}
  .zuix_layoutf_container .lnb .lnb_box {position:relative; width:100%; height:100%;}
	.zuix_layoutf_container .lnb .lnb_box_floating_bottom {position:relative; width:100%; height:calc(100% - 66px);}
  .zuix_layoutf_container .content {display:flex; flex:1 1 auto; flex-direction:column; width:100%; height:100%; justify-content:center; box-sizing:border-box;}
	.zuix_layoutf_container .mobile_button_header,
	.zuix_layoutf_container .mobile_button_lnb {display:none}
	.zuix_layoutf_container .account_box {display:none}
	.zuix_layoutf_container .logo {height:100%; display:flex; align-items:center; padding-left:30px;}
  .zuix_layoutf_container .account_info_icon {display:none;}
  .zuix_layoutf_container .floating_bottom {	position: absolute; left: 0; background-color: #fff; width: 100%}

  .zuix_layoutf_mobile_header_contents {display:none;}

    .zuix_layoutg_container {position:relative; display:flex; flex-direction:column; width:100%; height:100%; min-width:1400px; overflow:hidden; }
    .zuix_layoutg_container .zuix_layoutg_content {display:flex; flex-direction:column; flex-grow:1;  width:1200px; margin:0 auto;}
    .zuix_layoutg_container .zuix_layoutg_content.detail {max-width: 600px !important; padding-bottom:60px; }
    .zuix_layoutg_container .zuix_layoutg_contentb {width: 1200px; overflow:hidden; margin:0 auto; display:flex; flex-direction: row; background:#eee; justify-content: space-between; flex-grow:1;}
    .zuix_layoutg_container .zuix_layoutg_content_list {width:708px; overflow:hidden; background:#fff;}
    .zuix_layoutg_container .zuix_layoutg_content_list > div {padding-left: 10px; padding-right: 10px;}
    .zuix_layoutg_container .zuix_layoutg_content_detail {width: 480px; overflow:hidden;  background:#fff; border-left:1px solid #e1e1e1; border-right:1px solid #e1e1e1;}

    .zuix_grida_container {
        flex:1 1 auto;
        display: flex; display: -webkit-flex; width:100%;
        flex-flow: row wrap; -webkit-flex-flow: row wrap;
        background: #fff; justify-content: center;
    }
    .zuix_gridb_container, .zuix_gridc_container {
        display: flex; display: -webkit-flex; width:100%;
        flex-flow: row wrap; -webkit-flex-flow: row wrap;
        justify-content: center;
    }
	.zuix_gridc_container {
		align-items: center
	}
	.zuix_gridc_container.top {
		align-items: flex-start
	}
	.zuix_gridc_container.bottom {
		align-items: flex-end
    }
    .zuix_gridd_container {
        min-width: 100%;
        display: flex; display: -webkit-flex;
        flex-flow: row wrap; -webkit-flex-flow: row wrap;
        margin-left: -5px; margin-right: -5px; margin-bottom: -10px;
        background: #fff;
    }

    .zuix_grida_content {
        max-width: 600px; flex:1 1 auto; width:100%;
    }
    .zuix_grida_secondary {
        max-width: 400px; max-height: 300px;
    }
    .zuix_gridb_content {
        flex-grow:1;
    }
    .zuix_gridb_secondary {
        flex-shrink:1
    }
    .zuix_gridc_content, .zuix_gridc_secondary {
        width: 50%;
        display:flex;
        flex:1 1 auto;
        flex-direction: column;
    }
    .zuix_gridd_grid {
        width: 20%;
    }
    .zuix_gridd_content {
        padding-left: 5px; padding-right: 5px; padding-bottom: 10px;
    }
    .zuix_gride_container {display:flex; width:100%;}
    .zuix_gride_content {
    }

	.zuix_listItemI_item {
		display:flex; align-items:center; flex-direction:column;
		flex-basis:14%;
    }
    .zuix_listItemI_item_Block {
		flex-basis:25% !important;
	}

	.TabDItem {
		display:flex; flex:0; flex-basis:25%;
	}
    .TabDItem_Shrink {flex:0 !important; flex-basis:25% !important}
    .TabDItem_Block {flex:1 !important; flex-basis:0% !important}

	.zuix_SelectB_item {
		display:flex; box-sizing:border-box;
		border:1px solid #ccc; border-left:0; margin-top:-1px;
		overflow:hidden;
	}
	.zuix_SelectB_item.xs_3_5 {
		max-width:20%; flex-basis:20%;
	}
	.zuix_SelectB_item.xs_2 {
		max-width:50%; flex-basis:50%;
	}
	.zuix_SelectB_item.xs_3 {
		max-width:33.3%; flex-basis:33.3%;
	}
	.zuix_SelectB_item.xs_4 {
		max-width:25%; flex-basis:25%;
	}
	.zuix_SelectB_item.xs_5 {
		max-width:20%; flex-basis:20%;
	}
	.zuix_SelectB_item.xs_2:nth-child( 2n + 3 ),
	.zuix_SelectB_item.xs_3:nth-child( 3n + 4 ),
	.zuix_SelectB_item.xs_4:nth-child( 4n + 5 ),
	.zuix_SelectB_item.xs_5:nth-child( 5n + 6 ) {
		border-left:1px solid #ccc;
	}
	.zuix_SelectB_item:first-of-type {
		border-left:1px solid #ccc;
	}

    .zuix_SelectB_item:first-of-type {
        border-top-left-radius: 4px;
    }

    .zuix_SelectB_item:last-of-type {
        border-bottom-right-radius: 4px;
    }

    .zuix_SelectB_item.topRightRadius {
        border-top-right-radius: 4px;
    }
    .zuix_SelectB_item.bottomLeftRadius {
        border-bottom-left-radius: 4px;
    }

    .zuix_SelectB_item.bottomRightRadius {
        border-bottom-right-radius: 4px;
    }

	.zuix_footer_pc, .zuix_footer_mob {
		display:none
	}

	.zuix_TableD_Row {
		display:flex;
		flex:1; flex-direction: row;
		padding-top:11px; padding-bottom:11px; min-width:50%;
	}
	.zuix_TableD_Row > div:nth-child(1n+2) {
		width:  calc(100% - 120px);
		word-break:break-all;
		word-wrap: break-word;
		flex-wrap:wrap;
		flex-basis:auto;
		overflow-wrap: break-word;
	}

	.cctvItem {min-width:50%; width:50%; max-width:50%;}
	.cctvTitle {}
    .zuix_tableF {width:100%; border-collapse:collapse; border-spacing:0; table-layout: fixed}
    .zuix_tableF thead tr {background:#ccc !important; border:none; cursor:initial;}
    .zuix_tableF thead td {height:34px; padding-right:10px; vertical-align:middle !important}
    .zuix_tableF.odd tbody > tr {background:#fff;}
    .zuix_tableF.odd tbody > tr:nth-of-type(even) {background:#fafafa;}
    .zuix_tableF tbody tr:hover,
    .zuix_tableF.odd tbody tbody tr:nth-of-type(even):hover {background:rgba(224,236,252, 0.4);}
    .zuix_tableF tr.selected {background:rgba(224,236,252, 0.4) !important;}

    .zuix_tableF.odd tbody > tr.disabled:nth-of-type(even) {background:#fafafa;}

    .zuix_tableF tr > td:first-child {padding-left:10px;}
    .zuix_tableF tr {border-bottom:1px solid #e1e1e1; cursor:pointer}
    .zuix_tableF tbody td {padding-right:10px; height: 30px; vertical-align:middle !important}
    .zuix_tableF td.td_align_left {text-align:left;}
    .zuix_tableF td.td_align_right {text-align:right;}
    .zuix_tableF td.td_align_center {text-align:center;}
    .zuix_tableF .thead_td_wrap {display:flex; width:100%; overflow:hidden; flex-direction:row; align-items:center; text-overflow: ellipsis}
    .zuix_tableF .td_wrap {position:relative; padding-top:5px; padding-bottom:5px}
    .zuix_tableF .td_content_wrap {overflow:hidden; text-overflow: ellipsis}
    .zuix_tableF .tableF_2line_item {padding:3px 0; font-size:14px; line-height: 18px; color: #757575;}
    .zuix_tableF .tableF_2line_item strong {display:block;}
    .zuix_tableF .td_wrap:hover .tableF_tooltip {display:block}
    .zuix_tableF .tableF_tooltip {display:none; position:absolute; z-index:1}
    .tableF_tooltip > div {position:relative; display:inline-block; margin-top:6px; padding:13px; background:#444; color:#fff; line-height:18px; font-size:12px; border-radius: 2px; border:1px solid #757575; opacity:0.98;}
    .tableF_tooltip:after {position:absolute; left:10px; top:0; content:""; width:13px; height:7px; background: url(https://s.zigbang.com/zuix/bg_content_tooltipa_arrow_13x7_nor_black.png) no-repeat center center; background-size:cover; z-index:2;}

	.zuix_listitemO:hover .tableF_tooltip {display:block}
    .zuix_listitemO .tableF_tooltip {display:none; position:absolute; z-index:1}

  .zuix_dialogA_container {display:flex; flex:1 0 auto; width:100%; max-height: 380px; overflow:hidden;}
	@media all and (max-width: 320px) {
		.zuix_dialogA_container {max-height: 275px}
    }

    .zuix_dialog_container {display:flex; flex:1 0 auto; width:100%; width: 296px; max-height: 380px; overflow:hidden;}
	@media all and (max-width: 320px) {
		.zuix_dialog_container {max-height: 275px;}
    }
    @media all and (min-width: 601px) {
        .zuix_dialog_container {max-height: 700px; width:482px;}
    }

  .zuix_responsive_dialog_container {display:flex; flex:1 0 auto; width:100%; width: 296px; max-height: 380px; overflow:visible; z-index:1;}
  @media all and (max-width: 320px) {
    .zuix_responsive_dialog_container {max-height: 275px;}
  }
  @media all and (min-width: 601px) {
    .zuix_responsive_dialog_container {max-height: 700px; width:482px;}
  }

	@media all and (max-width: 599px) {
		.zuix_TableD_Row {min-width:100%  !important;}
	}
	@media all and (min-width: 601px) { /* breakpoint xs  기준*/
		.zuix_SelectB_item.xs_3_5:nth-child( 5n + 6 ) {
			border-left:1px solid #ccc;
		}
	}
	@media all and (max-width: 600px) { /* breakpoint xs  기준*/
		.zuix_layouta_content {
            max-width: 100%; max-height: 100%; min-height: 100%; min-width: 320px;
        }

        .zuix_layoutb_content, .zuix_layoutc_content, .zuix_layoutd_content {
            width: 100%;
        }
        .zuix_layoutc_footer {
            padding-bottom: 50px;
        }
		.zuix_layoutb_footer.hasFixed {
			padding-bottom: 50px;
		}
        .zuix_layoutb_bottom_fixed, .zuix_layoutc_bottom_fixed {
            display: block;
        }
        .zuix_layoutc_header, .zuix_layoutd_header {
            display: none;
        }
        .zuix_layoutc_content, .zuix_layoutd_content {
            padding-top: 0px;
        }

        .zuix_gridb_content, .zuix_gridc_content, .zuix_gridc_secondary {
            width: 100% !important;
			flex:auto;
        }
        .zuix_gride_container {
            display:block;
        }
        div.zuix_gride_content {
            width: 100% !important;
        }
        .zuix_gridb_secondary {
            display: none;
        }
        .zuix_gridd_grid {
            width: 33%;
        }
        .zuix_grida_secondary {
            display: none;
        }
		.zuix_listItemI_item {flex-basis:25%;}
		.TabDItem {display:flex; flex: 1;}

		.zuix_SelectB_item {
			flex:0 1 auto;
		}
		.zuix_SelectB_item.xs_3_5 {
			max-width:33.3%; flex-basis:33.3%;
		}
		.zuix_SelectB_item.xs_3_5:nth-child( 3n + 4 ) {
			border-left:1px solid #ccc;
		}

		.cctvItem {flex-grow:1; max-width:100%; width:100%; min-width:100%;}
	}

	@media all and (max-width: 1080px) {
		.zuix_footer_mob {display:flex; flex:1 1 auto; flex-direction:column}
		.zuix_footer_pc {display:none}

		.zuix_layoutf_container {position:relative; padding:50px 0 0 0; overflow:hidden;}
		.zuix_layoutf_container .header {position:fixed; left:0; right:0; top:0; width:100%; height:50px; padding-right:50px; box-sizing:border-box;}
		.zuix_layoutf_container .lnb {display:none; position:fixed; left:0; right:0; top:0; width:100%; bottom:0; flex-direction:column; align-items:flex-end; background:rgba(0,0,0, 0.3); z-index:9999;}
		.zuix_layoutf_container .lnb.open {display:flex}
		.zuix_layoutf_container .lnb div.lnb_box {height:100%; width:240px; padding:50px 0 80px;  background:#fff; box-shadow:1px 2px 10px rgba(0,0,0,0.5); overflow-y: auto}
		.zuix_layoutf_container .lnb.open div.lnb_box {}
		.zuix_layoutf_container .lnb .mobile_button_lnb {display:block;; position:absolute; right:0; top:0; width:30px;}
		.zuix_layoutf_container .lnb .mobile_button_lnb button {font-size:20px;}
		.zuix_layoutf_container .content {width:100%; height:auto;}
		.zuix_layoutf_container .mobile_button_header {display:flex;; position:absolute; right:0; top:0; height:100%; width:50px; justify-content: center;align-items: center;}
		.zuix_layoutf_container .mobile_button_header button {width:30px; height:30px; background:none; border:none; padding-left: 0px; padding-right: 0px;}
		.zuix_layoutf_container .account_box.open {display:block; position:relative; z-index:9999}
		.zuix_layoutf_container .account_box.open .account_info {position:fixed; right:0; top:0; width:240px; height:50px; padding: 0 16px; background:#f6f6f6; z-index:2; box-sizing:border-box; align-items: center; display:flex; justify-content:space-between;}
    .zuix_layoutf_container .account_info_icon {display:block;}
    .zuix_layoutf_container .account_box.open .account_button {position:fixed; right:0; bottom:0; width:240px; z-index:2; padding:20px 16px; box-sizing:border-box; align-items:center; justify-content: center; background:#fff;}
		.zuix_layoutf_container .logo {padding-left:18px;}
    .zuix_layoutf_container_no_padding_top {padding-top:0 !important;}
    .zuix_layoutf_mobile_header_contents {display:block;width:100%;background-color:#FAFAFA;}
    .zuix_layoutf_container .floating_bottom {bottom:0;}



	}
	@media all and (min-width: 1081px) {
		.zuix_footer_pc {display:flex; flex:1 1 auto; flex-direction:row}
		.zuix_footer_mob {display:none;}

		.zuix_layoutf_container .account_box {display:flex; position:fixed; right:40px; top:0; height:65px; flex-direction:row; z-index:2; align-items: center;}
		.zuix_layoutf_container .account_box .account_button {margin-left:30px;}
	}

 
.rc-slider {
  position: relative;
  height: 4px;
  width: 100%;
  border-radius: 6px;
  background-color: #ccc;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #444;
  z-index: 1;
}
.rc-slider-handle {
  	position: absolute;
	z-index: 2;
	background: url(https://s.zigbang.com/www/images/ic_btn_handle_34x34_nor_white.png) no-repeat center center transparent;
	background-size: 100%;
	margin-top: -15px;
	width: 34px;
	height: 34px;
	cursor: pointer;
	cursor: -webkit-grab;
	cursor: grab;
	-ms-touch-action: pan-x;
	touch-action: pan-x;
}
.rc-slider-mark {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  font-size: 12px;
  z-index: 3;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  z-index: 1;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot:first-child {
  margin-left: -4px;
}
.rc-slider-dot:last-child {
  margin-left: -4px;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 4px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-track {
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  position: absolute;
  margin-left: -5px;
  margin-bottom: -7px;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 10px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
          animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
          animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 4;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

 
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #aeaeae;
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f0f0f0;
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
    }

    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
    }

    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff;
    }

    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
    .react-datepicker__month-read-view--down-arrow::before,
    .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #aeaeae;
    }

    .react-datepicker-wrapper {
    display: inline-block;
    }

    .react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    }

    .react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
    }

    .react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
    }

    .react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem;
    }

    .react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem;
    }

    .react-datepicker__triangle {
    position: absolute;
    left: 50px;
    }

    .react-datepicker-popper {
    z-index: 1;
    }

    .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px;
    }

    .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px;
    }

    .react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px;
    }

    .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
    left: auto;
    right: 42px;
    }

    .react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px;
    }

    .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
    left: 42px;
    right: auto;
    }

    .react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
    }

    .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
    }

    .react-datepicker__year-dropdown-container--select,
    .react-datepicker__month-dropdown-container--select,
    .react-datepicker__month-year-dropdown-container--select,
    .react-datepicker__year-dropdown-container--scroll,
    .react-datepicker__month-dropdown-container--scroll,
    .react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
    }

    .react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    }

    .react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
    }

    .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #ccc;
    }

    .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
    }

    .react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
    }

    .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #ccc;
    }

    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 80px;
    }

    .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
    }

    .react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
    }

    .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    }

    .react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
    }

    .react-datepicker__navigation--years-previous:hover {
    border-top-color: #b3b3b3;
    }

    .react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
    }

    .react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #b3b3b3;
    }

    .react-datepicker__month-container {
    float: left;
    }

    .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    }

    .react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 130px;
    }

    .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
    }

    .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
    box-sizing: content-box;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 30px;
	line-height: 30px;
    padding: 5px 10px;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: white;
    font-weight: bold;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #216ba5;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    color: #ccc;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
    }

    .react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    }

    .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
    }

    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
    white-space: nowrap;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    }

    .react-datepicker__day {
    cursor: pointer;
    }

    .react-datepicker__day:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
    }

    .react-datepicker__day--today {
    font-weight: bold;
    }

    .react-datepicker__day--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
    }

    .react-datepicker__day--highlighted:hover {
    background-color: #32be3f;
    }

    .react-datepicker__day--highlighted-custom-1 {
    color: magenta;
    }

    .react-datepicker__day--highlighted-custom-2 {
    color: green;
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
    }

    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
    background-color: #1d5d90;
    }

    .react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff;
    }

    .react-datepicker__day--keyboard-selected:hover {
    background-color: #1d5d90;
    }

    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(33, 107, 165, 0.5);
    }

    .react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: #f0f0f0;
    color: #000;
    }

    .react-datepicker__day--disabled {
    cursor: default;
    color: #ccc;
    }

    .react-datepicker__day--disabled:hover {
    background-color: transparent;
    }

    .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    }

    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view,
    .react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
    }

    .react-datepicker__year-read-view:hover,
    .react-datepicker__month-read-view:hover,
    .react-datepicker__month-year-read-view:hover {
    cursor: pointer;
    }

    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #b3b3b3;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
    }

    .react-datepicker__year-dropdown:hover,
    .react-datepicker__month-dropdown:hover,
    .react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
    }

    .react-datepicker__year-dropdown--scrollable,
    .react-datepicker__month-dropdown--scrollable,
    .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
    }

    .react-datepicker__year-option,
    .react-datepicker__month-option,
    .react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    }

    .react-datepicker__year-option:first-of-type,
    .react-datepicker__month-option:first-of-type,
    .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    }

    .react-datepicker__year-option:last-of-type,
    .react-datepicker__month-option:last-of-type,
    .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    }

    .react-datepicker__year-option:hover,
    .react-datepicker__month-option:hover,
    .react-datepicker__month-year-option:hover {
    background-color: #ccc;
    }

    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
    }

    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
    }

    .react-datepicker__year-option--selected,
    .react-datepicker__month-option--selected,
    .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
    }

    .react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 25%;
    right: 7px;
    }

    .react-datepicker__close-icon::after {
    background-color: #216ba5;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "×";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 0px;
    text-align: center;
    }

    .react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
    }

    .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
    }

    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
    }

    @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
    }

    .react-datepicker__portal .react-datepicker__current-month,
    .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
    }

    .react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
    }

    .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    }

    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
    }

    .react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
    }

    .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc;
    }

    .react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
    }

    .react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
    }
 
.gnb_root {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: white;
	height: 55px;
    min-height: 55px;
    max-height: 55px;
	min-width: 1440px;
    flex-shrink: 0;
    border-bottom:1px solid #e1e1e1;
}

.gnb_logo {
	width: 170px;
	height: 100%;
	overflow: hidden;
	object-fit: contain;
	margin-left: 30px;
	margin-right: 56px;
	cursor: pointer;
}

.gnb_logo:active {
	opacity: 0.6;
}

.gnb_menuarea {
	flex-direction: row;
	display: flex;
	flex: 1;
}

.gnb_menu {
	border: none;
	outline: none;
	background-color: transparent;
	cursor: pointer;
	margin-right: 36px;
	border-radius: 4px;
	font-size: 16px;
  padding: 0px;
  flex-shrink: 0;
}

.gnb_menu_normal {
	color: #222222;
}

.gnb_menu_normal:hover {
	color: #fa880b;
	font-weight: bold;
}

.gnb_menu_normal:active {
	color: #fa880b;
	font-weight: bold;
}

.gnb_menu_disabled {
	color: #a6a6a6;
	cursor: auto;
}

.gnb_menu_selected {
	color: #fa880b;
	font-weight: bold;
}

.gnb_optionarea {
	display: flex;
	flex-direction: row;
	margin-left: 62px;
	margin-right: 30px;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
}

 @media (min-width: 720px) {[data-media~="rnmq-76994c22"] {min-height:44px !important;}}
@media (max-width: 719px) {[data-media~="rnmq-71135933"] {min-height:41px !important;}}
@media (min-width: 720px) {[data-media~="rnmq-76021353"] {font-size:14px !important;line-height:18px !important;}}
@media (max-width: 719px) {[data-media~="rnmq-88f8cc2f"] {font-size:13px !important;line-height:17px !important;}}
@media (min-width: 720px) {[data-media~="rnmq-8ffb8ed8"] {font-size:16px !important;line-height:21px !important;}}
@media (max-width: 719px) {[data-media~="rnmq-a233a32e"] {font-size:15px !important;line-height:20px !important;}}